import { createMinimalBrowserClient } from '@slardar/web/client';
import { customPlugin } from '@slardar/integrations/esm/custom';
import { getSlardarEnv } from '@flow-studio/chat-core';
import { Reporter } from '@flow-infra/logger';

import { ChatSdkError, SDKErrorCode } from '@/util/error';
import { eventMeta } from '@/util/env';
import { type OpenReportEvent } from '@/log/event';

const slardarInstance = createMinimalBrowserClient();
slardarInstance.init({
  bid: 'bot_studio_sdk',
  /**
   * 与core上报到同一处 bid_env，打通数据
   */
  env: getSlardarEnv({
    env: 'thirdPart',
    deployVersion: 'release',
  }),
  integrations: [customPlugin()],
});
slardarInstance.start();

const eventPrefix = 'open_sdk_custom_event_';
const errorPrefix = 'open_sdk_custom_error_';

class OpenReporter extends Reporter {
  openSdkEvent(eventName: OpenReportEvent, meta: Record<string, unknown>) {
    this.event({
      eventName: eventPrefix + eventName,
      meta: {
        ...(meta ?? {}),
        ...eventMeta,
      },
    });
  }

  openSdkError(error: Error | unknown, code = SDKErrorCode.Base) {
    if (!(error instanceof Error)) {
      this.errorEvent({
        eventName: errorPrefix + SDKErrorCode.NotError,
        error: ChatSdkError.create(SDKErrorCode.NotError),
        meta: {
          error,
        },
      });
      return;
    }

    this.errorEvent({
      eventName: errorPrefix + code,
      error,
      meta: eventMeta,
    });
  }
}

export const clientReporter = new OpenReporter();

clientReporter.init(slardarInstance);
